import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";

const NewsIndexPage = () => (
  <Layout>
    <Seo title="News" />
	<h2>News</h2>
	<div className="main--centerwrapper">
		<StaticImage
		src="../../images/photos/pressRes/_CAT3918.jpg"
		alt="Jesse Rivest press image"
		placeholder="blurred"
		layout="constrained"
		className="main--centered"
		imgClassName="img--bordered-small"
		height={333}
		/>
	</div>
	<NewsHeader />
	<h3>May 2024</h3>
	<p>
		The most recent news is from <Link to="202405/">May 2024</Link>.
	</p>
	<h4>Choose a past month</h4>
	<ul>
		<li><Link to="202405/">2024 May</Link></li>
		<li><Link to="202310/">2023 October</Link></li>
		<li><Link to="202306/">2023 June</Link></li>
		<li><Link to="202303/">2023 March</Link></li>
		<li><Link to="202212/">2022 December</Link></li>
		<li><Link to="202209/">2022 September</Link></li>
		<li><Link to="202208/">2022 August</Link></li>
		<li><Link to="202206/">2022 June</Link></li>
		<li><Link to="202205/">2022 May</Link></li>
		<li><Link to="202204/">2022 April</Link></li>
		<li><Link to="202203/">2022 March</Link></li>
		<li><Link to="202201/">2022 January</Link></li>
		<li><Link to="202112/">2021 December</Link></li>
		<li><Link to="201301/">2013 January</Link></li>
		<li><Link to="201208/">2012 August</Link></li>
		<li><Link to="201201/">2012 January</Link></li>
		<li><Link to="201102/">2011 February</Link></li>
		<li><Link to="201012/">2010 December</Link></li>
		<li><Link to="201007/">2010 July</Link></li>
		<li><Link to="201005/">2010 May</Link></li>
		<li><Link to="201003/">2010 March</Link></li>
		<li><Link to="201002/">2010 February</Link></li>
		<li><Link to="201001/">2010 January</Link></li>
		<li><Link to="200911/">2009 November</Link></li>
		<li><Link to="200910/">2009 October</Link></li>
		<li><Link to="200909/">2009 September</Link></li>
		<li><Link to="200905/">2009 May</Link></li>
		<li><Link to="200904/">2009 April</Link></li>
		<li><Link to="200903/">2009 March</Link></li>
		<li><Link to="200902/">2009 February</Link></li>
		<li><Link to="200901/">2009 January</Link></li>
		<li><Link to="200812/">2008 December</Link></li>
	</ul>
  </Layout>
);

export default NewsIndexPage;
